:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: #212529bf;
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: #21252980;
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem #00000026;
  --bs-box-shadow-sm: 0 .125rem .25rem #00000013;
  --bs-box-shadow-lg: 0 1rem 3rem #0000002d;
  --bs-box-shadow-inset: inset 0 1px 2px #00000013;
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: #0d6efd40;
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

img, svg {
  vertical-align: middle;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  margin: 0;
}

button, [type="button"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

summary {
  cursor: pointer;
  display: list-item;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-12 {
  flex: none;
  width: 100%;
}

@media (width >= 576px) {
  .col-sm-12 {
    flex: none;
    width: 100%;
  }
}

@media (width >= 768px) {
  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

:not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

:not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), .8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), .3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), .15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius)  - (var(--bs-border-width)));
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), .03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: .75rem;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.d-block {
  display: block !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

@media (width >= 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  font-family: sans-serif;
  line-height: 1.15;
}

article, header, nav, section {
  display: block;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

b, strong {
  font-weight: bolder;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
}

[type="button"]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.h5, .h6, h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.col-md-12, .col-md-4, .col-md-6, .col-sm-12 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (width >= 576px) {
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (width >= 768px) {
  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.collapse:not(.show) {
  display: none;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar .container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

@media (width <= 991.98px) {
  .navbar-expand-lg > .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .active > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card-body {
  flex: auto;
  min-height: 1px;
  padding: 1.25rem;
}

.bg-dark {
  background-color: #343a40 !important;
}

.d-flex {
  display: flex !important;
}

.h-100 {
  height: 100% !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  img {
    page-break-inside: avoid;
  }

  h2, .h2, h3, .h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, .h2, h3, .h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }
}

.subtitle {
  letter-spacing: .2em;
  text-transform: uppercase;
  margin-top: 2rem;
  font-weight: 700;
  color: #e646a6 !important;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

*, :after, :before {
  box-sizing: border-box;
}

p {
  margin-block: 1em;
  margin-inline: 0;
  display: block;
}

.text-center {
  text-align: center !important;
  text-align: center !important;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
}

.starter-template {
  text-align: center;
  padding: 40px 15px;
}

.intro {
  cursor: pointer;
  background-color: #f6f6f6;
  min-height: 340px;
  padding: 20px;
}

.imgholder {
  text-align: center;
  margin: 20px 0;
}

.familia {
  min-height: 250px;
}

.cabezafamilia {
  border-bottom: 1px dashed #eee;
  min-height: 80px;
  margin: 20px 0;
}

.cabezafamilia img {
  padding: 3px;
}

.espaciovertical {
  margin: 30px 0;
}

.seccioncontenido {
  margin-top: 40px;
  margin-bottom: 40px;
}

.confondo {
  color: #666;
  background: #f6f6f6;
}

#footer {
  color: #ddd;
  background-color: #333;
  border-top: 1px solid #f6f6f6;
  margin-bottom: 0;
  padding: 30px 0;
}

.bulletsspace {
  padding-left: 15px;
}

.bulletsspace li, .nobullets li {
  padding-bottom: 1.5rem;
}

.bulletsspace li:first-child, .nobullets li:first-child {
  padding-top: 1.5rem;
}

.nobullets {
  padding-left: 0;
  list-style: none;
}

#copyright {
  padding-top: 30px;
}

.navbar {
  background-color: #333;
}

@media (width <= 767px) {
  .imgholder {
    text-align: center;
    margin: 20px -20px;
  }
}

h3, .h3 {
  font-size: 1.5rem !important;
}

.shadow {
  box-shadow: 0 0 1rem #00000026 !important;
}

h4.text-uppercase, .text-uppercase.h4 {
  color: #fff !important;
}

@font-face {
  font-family: Poppins;
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local(Poppins Regular), local(Poppins-Regular), url("https://fonts.gstatic.com/s/poppins/v9/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
  display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.banner {
  place-content: center;
  width: 100%;
  display: none;
}

.listado_cursos {
  margin-top: 3rem;
}

/*# sourceMappingURL=index.49f0b6ff.css.map */
